import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../components/Auth';
import {get_vars_cfg} from '../config/vars'; /* eslint-disable-line */
import {Loading} from '../components/Loading';

const ToShopify = () => 
{
    console.log("ToShopify")

    const {customer_id: customerId, sfyurl: retb64, store_name: inputStoreName} = useParams();

    console.log("customerId: ", customerId);
    console.log("retb64: ", retb64);

    const varsCfg = get_vars_cfg();

    const apiEndPoint = varsCfg.api_endpoint;
    const errorPage = varsCfg.error_page;
    const defaultStoreName = varsCfg.store_name;

    const storeName = inputStoreName || defaultStoreName;

    console.log("storeName: ", storeName)

    // // next, get the value of "ret" from the query string
    // // const defaultRet = `https://${storeName}.account.myshopify.com`;
    // const defaultRet = `https://${storeName}.myshopify.com/account`;
    // const urlParams = new URLSearchParams(window.location.search);
    // const ret = urlParams.get('ret') || defaultRet;

    const ret = retb64 ? atob(retb64) : `https://${storeName}.myshopify.com/account`;
    console.log("ret: ", ret);

    const auth = useContext(AuthContext);

    console.log("Auth", auth);

    // const hstreamId = auth?.tokens?.idTokenObj?.hstreamid;

    // console.log("hstreamId", hstreamId);

    const accessToken = auth?.tokens?.accessToken;

    console.log("accessToken", accessToken);

    const [shopifyUrl, setShopifyUrl] = useState(null);

    console.log("shopifyUrl", shopifyUrl);

    useEffect(() => {
        if (accessToken)
        {
            // call our api to get the induction url
            const url = `https://${apiEndPoint}/api/v1/s/${storeName}/sfy-sign-in${customerId ? "/customer/": ""}${customerId || ""}?ret=${ret}`;

            console.log("url", url);

            // headers for authentication 
            const headers = {
                'authorization': `Bearer ${accessToken}`,
                'content-type': 'application/json',
            };

            // we need to POST to the api to get the url
            fetch(url, {
                method: 'POST',
                headers,
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        console.log("data", data);
                        setShopifyUrl(data.url);
                    });
                }
                else {
                    setShopifyUrl(errorPage);
                }
            }).catch((error) => {
                console.log("error", error);
                setShopifyUrl(errorPage);
            });
        }
    }, [customerId, accessToken, apiEndPoint, errorPage]);

    useEffect(() => {
        if (shopifyUrl)
        {
            // throw away our tokens
            auth.clearTokens();
            // redirect to the shopify url
            window.location.href = shopifyUrl;
        }
    }, [shopifyUrl]);

    return <Loading />;
};

export {ToShopify};

