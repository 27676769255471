import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
// import { useInductionUrl } from '../helpers/useGeneral';
import { CircularProgress } from '@mui/material';
import { AuthContext } from '../components/Auth';
import {get_vars_cfg} from '../config/vars'; /* eslint-disable-line */
import {Loading} from '../components/Loading';

const ToHLC = (props) => 
{
    console.log("ToHLC")
    
    const {order_id: orderId, store_name: inputStoreName} = useParams();

    const varsCfg = get_vars_cfg();

    const defaultStoreName = varsCfg.store_name;

    const storeName = inputStoreName || defaultStoreName;

    console.log("storeName: ", storeName)

    console.log("orderId: ", orderId);

    const apiEndPoint = varsCfg.api_endpoint;
    const errorPage = varsCfg.error_page;

    const auth = useContext(AuthContext);

    console.log("Auth", auth);

    // const hstreamId = auth?.tokens?.idTokenObj?.hstreamid;

    // console.log("hstreamId", hstreamId);

    const idToken = auth?.tokens?.idToken;
    const accessToken = auth?.tokens?.accessToken;

    console.log("accessToken", accessToken);
    console.log("idToken", idToken);

    const [inductionUrl, setInductionUrl] = useState(null);

    console.log("inductionUrl", inductionUrl);

    useEffect(() => {
        if (orderId && storeName && idToken && accessToken)
        {
            // call our api to get the induction url
            const url = `https://${apiEndPoint}/api/v1/s/${storeName}/fulfill-order/${orderId}?id_token=${idToken}`;

            console.log("url", url);

            // headers for authentication 
            const headers = {
                'authorization': `Bearer ${accessToken}`,
                'content-type': 'application/json',
            };

            // we need to POST to the api to get the url
            fetch(url, {
                method: 'POST',
                headers,
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        console.log("data", data);
                        setInductionUrl(data.url);
                    });
                }
                else {
                    setInductionUrl(errorPage);
                }
            }).catch((error) => {
                console.log("error", error);
                setInductionUrl(errorPage);
            });
        }
    }, [orderId, storeName, idToken, accessToken]);

    useEffect(() => {
        if (inductionUrl)
        {
            // throw away our tokens
            auth.clearTokens();
            // redirect to the induction url
            window.location.href = inductionUrl;
        }
    }, [inductionUrl]);

    return <Loading />;
};

export {ToHLC};

