import { Navigate, useRoutes, Redirect } from 'react-router-dom';
// layouts
import EmptyLayout from './layouts/Empty';
//
import { ToHLC } from './pages/ToHLC';
import { ToCourses } from './pages/ToCourses';
import { ToShopify } from './pages/ToShopify';
import { ToPage } from './pages/ToPage';
import { AuthGuard } from './components/Auth';
import { get_vars_cfg } from './config/vars'; /* eslint-disable-line */

// ----------------------------------------------------------------------

export default function Router() {

  const varsCfg = get_vars_cfg();
  const errorPage = varsCfg.error_page;

  const hpaUrl = varsCfg.hstream_hpa_url;

  return useRoutes([
    {
      path: 'order',
      element: <AuthGuard hpaUrl={hpaUrl} register><EmptyLayout /></ AuthGuard>,
      children: [
        { 
          path: ':order_id', 
          element: <ToHLC /> 
        },
      ]
    },
    {
      path: 's/:store_name/order',
      element: <AuthGuard hpaUrl={hpaUrl} register><EmptyLayout /></ AuthGuard>,
      children: [
        { 
          path: ':order_id', 
          element: <ToHLC /> 
        },
      ]
    },
    {
      path: '/',
      element: <AuthGuard hpaUrl={hpaUrl} suppressRegister><EmptyLayout /></ AuthGuard>,
      children: [
        // { 
        //   path: 'order/:order_id', 
        //   element: <ToHLC /> 
        // },
        { 
          path: 'courses', 
          element: <ToCourses /> 
        },
        { 
          path: 'sfy-sign-in/c/:customer_id', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/sfyurl/:sfyurl', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/c/:customer_id/sfyurl/:sfyurl',
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/:customer_id', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in', 
          element: <ToShopify /> 
        },
        {
          path: '',
          element: <ToPage page={errorPage} />,
        },
        {
          path: '*',
          element: <ToPage page={errorPage} />,
        }
      ],
    },
    {
      path: 's/:store_name/',
      element: <AuthGuard hpaUrl={hpaUrl} suppressRegister><EmptyLayout /></ AuthGuard>,
      children: [
        // { 
        //   path: 'order/:order_id', 
        //   element: <ToHLC /> 
        // },
        { 
          path: 'courses', 
          element: <ToCourses /> 
        },
        { 
          path: 'sfy-sign-in/c/:customer_id', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/sfyurl/:sfyurl', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/c/:customer_id/sfyurl/:sfyurl',
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in/:customer_id', 
          element: <ToShopify /> 
        },
        { 
          path: 'sfy-sign-in', 
          element: <ToShopify /> 
        },
        {
          path: '',
          element: <ToPage page={errorPage} />,
        },
        {
          path: '*',
          element: <ToPage page={errorPage} />,
        }
      ],
    }
  ]);
}
