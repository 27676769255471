import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import * as qs from 'qs'
import { Loading } from './Loading';
import { get_vars_cfg } from '../config/vars'; /* eslint-disable-line */

// const jwt = require('jsonwebtoken');

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const varsCfg = get_vars_cfg();
  const hstreamSubdomain = varsCfg.hstream_subdomain;
  const clientId = varsCfg.hstream_client_id;

  const [tokens, setTokens] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Check for tokens on the query string.

  // first get access to the query string
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    // do we have access_token, id_token, and refresh_token?
    let accessToken = params.get('access_token');
    let idToken = params.get('id_token');
    const refreshToken = params.get('refresh_token');

    if (accessToken || idToken || refreshToken) {
      // remove the three tokens from the query string, without using params
      // because that would cause a re-render
      const currentUrl = new URL(window.location.href);
      if (accessToken)
      { 
        currentUrl.searchParams.delete('access_token');
      }
      if (idToken)
      {
        currentUrl.searchParams.delete('id_token');
      }
      if (refreshToken)
      {
        currentUrl.searchParams.delete('refresh_token');
      }
      window.history.replaceState({}, window.location.hostname, currentUrl.href);
    } else {
      // if we didn't get an access token, check the session storage
      console.log('no tokens on query string, checking session storage');
      accessToken = sessionStorage.getItem('Hstm.Identity.AccessToken');
      idToken = sessionStorage.getItem('Hstm.Identity.IdentityToken');
    }

    // now, if we've got tokens, decode them
    if (accessToken || idToken) {
      try
      {
        console.log("Decoding tokens", accessToken, idToken);

        const accessTokenObj = accessToken ? jwtDecode(accessToken) : null;
        const idTokenObj = idToken ? jwtDecode(idToken) : null;

        setTokens({
          accessToken, 
          accessTokenObj, 
          idToken,
          idTokenObj
        })
      }
      catch (error) {
        console.log(error);
        setTokens(null);
      }
    }
    else
    {
      setTokens(null);
    }

    setIsInitialized(true);
  }, []);

  useEffect(() => {
    console.log('set tokens', tokens);
    if (tokens) {
      // write to session storage
      if (tokens.accessToken) {
        sessionStorage.setItem('Hstm.Identity.AccessToken', tokens.accessToken);
      }
      else
      {
        sessionStorage.removeItem('Hstm.Identity.AccessToken');
      }
      if (tokens.idToken) {
        sessionStorage.setItem('Hstm.Identity.IdentityToken', tokens.idToken);
      }
      else
      {
        sessionStorage.removeItem('Hstm.Identity.IdentityToken');
      }
      sessionStorage.removeItem('Hstm.Identity.RefreshToken'); // just in case. Never store it.
    }
  }, [tokens]);

  const signOut = useCallback(() => {
    console.log('signing out');
    setTokens({});
  }, []);

  const clearTokens = useCallback(() => {
    console.log('clearing tokens');
    // clear them only from session storage
    sessionStorage.removeItem('Hstm.Identity.AccessToken');
    sessionStorage.removeItem('Hstm.Identity.IdentityToken');
    sessionStorage.removeItem('Hstm.Identity.RefreshToken');
  }, []);

  return (
      <AuthContext.Provider
        value={{
          tokens,
          isInitialized,
          signOut,
          clearTokens
        }}
      >
        {children}
      </AuthContext.Provider>
  );
}

export const AuthGuard = ({ hpaUrl, register, suppressRegister, children }) => {
    const { tokens, isInitialized } = useContext(AuthContext);
    const {order_id: orderId, store_name: inputStoreName} = useParams();

    const theHpaUrl = hpaUrl || process.env.REACT_APP_HPA_URL || "https://portal.hstream.net";

    const varsCfg = get_vars_cfg();

    const storeClientId = varsCfg[`${inputStoreName}_client_id`]

    const clientId = storeClientId || varsCfg.hstream_client_id;
  
    console.log("AuthGuard", tokens);

    const defaultStoreName = varsCfg.store_name;

    const storeName = inputStoreName || defaultStoreName;

    console.log("storeName: ", storeName)

    if (!isInitialized) {
      return <Loading />;
    }

    const isAuthenticated = tokens && tokens.accessTokenObj

    if (!isAuthenticated) {
      // we need to remove all querystring parameters from window.location.href before using it as the returnURL
      const workingUrl = new URL(window.location.href);

      // remove the query string
      workingUrl.search = '';

      // remove the hash
      workingUrl.hash = '';

      // now create the return URL
      const returnUrl = encodeURIComponent(workingUrl.href);

      // const returnUrl = encodeURIComponent(window.location.href);

      const pathElem = register ? 'register' : 'sign-in'; 

      const redirectUrl = `${theHpaUrl}/account/${pathElem}/?client_id=${clientId}&redirect_uri=${returnUrl}&remember_me=true&force_sign_in=true&suppress_register=${suppressRegister?'true':'false'}`;

      console.log("redirecting to HPA", redirectUrl);

      window.location.replace(redirectUrl);
      // return <ButtonForRedirect redirectUrl={redirectUrl} />;
    }

    return <>{children}</>
}
