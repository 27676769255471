
export function get_vars_cfg() {
    return  {
  "abboths_client_id": "4d5lgp8fbjeokbjfvjjo88ciuj",
  "repdirecths_client_id": "2per8rq26l2c8841nn2iuvavfu",
  "cmecourseshstm_client_id": "3s0sht76a51td30peh5t98hqli",
  "hstream_client_id": "5hccm0gp6b3f7jvvb5mnn12n22",
  "hstream_hpa_url": "https://portal.hstream.net",
  "api_endpoint": "wwm-sn1-snpr-sfy-ind-fastapi-gw.ww.hstream.com",
  "error_page": "https://smartnursece.myshopify.com/pages/error-page",
  "store_name": "smartnursece",
  "hstream_subdomain": "hstream.net"
}
};
