import React, { useEffect } from 'react';

const ToPage = ({page}) => 
{
    console.log("ToPage")

    useEffect(() => {
        if (page)
        {
            window.location.href = page;
        }
    }, [page]);

    return <></>;
};

export {ToPage};

