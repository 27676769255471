import { Box, CircularProgress } from '@mui/material';

export const Loading = () => {
    const z = 9999;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <CircularProgress size={100} />
        </Box>
    );
}